import React from 'react'
import { useTranslation } from 'react-i18next'
import { East as EastIcon } from '@mui/icons-material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { AppBar, Box, Button, Dialog, Grid, IconButton, Slide, Stack, Toolbar, Typography } from '@mui/material'

import { CompanyLogo } from '@/common/components'
import { ParticipantsImg } from '@/common/components/ParticipantsImg'
import { TrainingParticipationData } from '@/features/my/types'
import FontFaceLoader from '@/theme/CustomFont'
import { TransitionProps } from '@mui/material/transitions'
import { CustomRadioGroup } from '../CustomRadioGroup/CustomRadioGroup'
import LanguageButton from '../LanguageButton/LanguageButton'
import { useTrainingsStartTenantsTenantIdTrainingsTrainingCodeTrainingCyclesTrainingCycleIdStartPostMutation } from '@/services/api/tenant/trainings'
import { RootState, useTypedSelector } from '@/store'
import ImageToSvgConverter from '@/common/components/ImageToSvgConverter/ImageToSvgConverter'
import {
  AppBarFooterStyle,
  AppBarHeaderStyle,
  BackButtonStyle,
  BoxStyle,
  ContinueButtonStyle,
  CourseSelectTypographyStyle,
  DescriptionTypographyStyle,
  IconButtonStyle,
  ImprintButtonStyle,
  PageLanguageTypographyStyle,
  TitleTypographyStyle,
  WelcomeTypographyStyle
} from './TrainingDialogsStyles'

const Transition = React.forwardRef(
  (props: TransitionProps & { children: React.ReactElement }, ref: React.Ref<unknown>) => (
    <Slide direction="up" ref={ref} {...props} />
  )
)

type TrainingDialogsProps = { open: boolean; handleClose: () => void; training: TrainingParticipationData | undefined }

export const TrainingDialogs = ({ open, training, handleClose }: TrainingDialogsProps): JSX.Element => {
  const { t } = useTranslation('general')
  const [selectedLanguage, setSelectedLanguage] = React.useState('en')
  const accessedTenant = useTypedSelector((state: RootState) => state.authSlice.tenant)

  const [startTrainingMutation] =
    useTrainingsStartTenantsTenantIdTrainingsTrainingCodeTrainingCyclesTrainingCycleIdStartPostMutation()

  const handleLanguageClick = (languageCode: string) => {
    setSelectedLanguage(languageCode)
  }
  const [selectedLanguageId, setSelectedLanguageId] = React.useState<string>('')

  const languages = [
    { language: 'Deutsch', code: 'de' },
    { language: 'English', code: 'en' }
  ]

  const startTraining = async () => {
    try {
      const result = await startTrainingMutation({
        tenantId: accessedTenant.id,
        trainingCode: training?.trainingCode || '',
        trainingCycleId: training?.trainingCycleId || '',
        trainingStartInDto: {
          course_variant_id: selectedLanguageId
        }
      }).unwrap()

      window.location.replace(result.courseplay_url)
    } catch (error) {
      console.error(error)
    }
  }

  const handleLanguageSelect = (id: string) => {
    setSelectedLanguageId(id)
  }

  return (
    <>
      <FontFaceLoader />
      <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
        <AppBar sx={AppBarHeaderStyle}>
          <Toolbar>
            <IconButton edge="start" color="inherit" sx={IconButtonStyle}>
              {accessedTenant.logo_url ? (
                <ImageToSvgConverter imageString={accessedTenant.logo_url} />
              ) : (
                <CompanyLogo />
              )}
            </IconButton>
          </Toolbar>
        </AppBar>
        <Box sx={BoxStyle}>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            spacing={2}
            sx={{
              maxWidth: 'sm',
              marginBottom: '111px'
            }}>
            <Grid item xs={12} sx={{ display: 'block' }}>
              <ParticipantsImg />
            </Grid>
            <Grid item xs={12} sx={{ display: 'block' }}>
              <Typography sx={WelcomeTypographyStyle}>{t('trainings_welcome')}</Typography>
            </Grid>
            <Grid item xs={12} sx={{ display: 'block' }}>
              <Typography variant="h4" sx={DescriptionTypographyStyle}>
                {t('trainings_description')}
              </Typography>
            </Grid>
            <Grid item xs={12} sx={{ display: 'block' }}>
              <Typography variant="h4" sx={TitleTypographyStyle}>
                {training ? training.title : 'Default Title'}.
              </Typography>
            </Grid>
            <Grid item xs={12} sx={{ display: 'block' }}>
              <Typography variant="h4" sx={CourseSelectTypographyStyle}>
                {t('trainings_courseSelect')}
              </Typography>
            </Grid>
            <Grid item xs={12} sx={{ display: 'block' }}>
              <CustomRadioGroup
                defaultValue="optionA"
                courseId={training?.courseId || ''}
                onLanguageSelect={handleLanguageSelect}
              />
            </Grid>
            <Grid item xs={12} sx={{ display: 'block' }}>
              <Button
                variant="outlined"
                endIcon={<EastIcon fontSize="small" />}
                sx={ContinueButtonStyle(accessedTenant?.theme_color)}
                onClick={startTraining}>
                {t('trainings_continueButton')}
              </Button>
            </Grid>
          </Grid>
        </Box>
        <AppBar sx={AppBarFooterStyle}>
          <Toolbar>
            <Grid container alignItems="baseline">
              <Grid xs={4} item>
                <Button
                  autoFocus
                  onClick={handleClose}
                  sx={BackButtonStyle}
                  startIcon={<ArrowBackIcon fontSize="small" />}>
                  back to my account
                </Button>
                <Button href="https://lawpilots.com/de/datenschutzerklaerung/" sx={ImprintButtonStyle}>
                  Data Protection
                </Button>
                <Button href="https://lawpilots.com/de/impressum/" sx={ImprintButtonStyle}>
                  Imprint
                </Button>
              </Grid>
              <Grid sx={{ width: 'max-content', ml: 16 }} xs={4} container item>
                <Stack direction="row" spacing={0.5} alignItems={'baseline'}>
                  <Typography sx={PageLanguageTypographyStyle}>Show this page in</Typography>
                  {languages.map(({ language, code }) => (
                    <LanguageButton
                      key={code}
                      language={language}
                      languageCode={code}
                      selectedLanguage={selectedLanguage}
                      onClick={handleLanguageClick}
                    />
                  ))}
                </Stack>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
      </Dialog>
    </>
  )
}
