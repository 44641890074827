import React, { useEffect } from 'react'
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import { Box, Button, CardActions, CardContent, Typography } from '@mui/material'
import { TrainingParticipationData } from '@/features/my/types'
import { TrainingDialogs } from '../TrainingDialogs/TrainingDialogs'
import { CardGridStack } from '@/common/components/CardGridStack'
import { Compliance } from '@/icons/Compliance'
import { DataProtection } from '@/icons/DataProtection'
import FontFaceLoader from '@/theme/CustomFont'
import { useTranslation } from 'react-i18next'
import { isPast, parseISO } from 'date-fns'
import cardListStyles, { textStyles } from './CardListStyles'
import { useHistory } from '@/hooks'

type cardListProps = {
  list: TrainingParticipationData[]
}

export const CardList = ({ list }: cardListProps): JSX.Element => {
  const { t } = useTranslation('general')
  const [open, setOpen] = React.useState(false)
  const [trainingSelected, setTrainingSelected] = React.useState<TrainingParticipationData>()
  const isOverdue = (endDate: string | undefined) => endDate && isPast(parseISO(endDate))
  const history = useHistory()

  const handleTraining = (training: TrainingParticipationData) => {
    if (training.status === 'in_progress' && training.coursePlayUrl) {
      window.location.href = training.coursePlayUrl
    } else if (training.status === 'invited') {
      setTrainingSelected(training)
      setOpen(true)
    } else {
      history.push(`/my/trainings/${training.courseId}`)
    }
  }

  const handleClickOpen = (training: TrainingParticipationData) => {
    handleTraining(training)
  }

  useEffect(() => {
    const trainingCode = location.pathname.split('/').slice(-1)[0]
    if (trainingCode) {
      const training = list.find((training) => training.courseId === trainingCode)
      if (training) {
        handleTraining(training)
      } else {
        setOpen(false)
      }
    }
  }, [location.pathname, list])

  const handleClose = () => {
    history.push('/my/trainings')
  }

  return (
    <Box sx={cardListStyles.container}>
      <FontFaceLoader />
      <CardGridStack
        xs={12}
        sm={12}
        items={list?.slice(0).map((training: TrainingParticipationData) => (
          <Box>
            <CardContent>
              <CardActions disableSpacing>
                {training?.status === t('training_overdue') && <Compliance />}
                {training?.status === t('training_completed') && <DataProtection />}
                {!training?.status && <Compliance />}
                {isOverdue(training?.trainingEndDate) ? (
                  <Button sx={cardListStyles.overdueButton} startIcon={<AccessAlarmIcon />}>
                    {t('training_overdue')}
                  </Button>
                ) : training.status === 'completed' ? (
                  <Button sx={cardListStyles.completedButton} startIcon={<CheckCircleOutlineIcon />}>
                    {t('training_completed')}
                  </Button>
                ) : (
                  <Typography sx={cardListStyles.buttonTypography}>
                    {t('training_dueDate')} {training?.trainingEndDate}
                  </Typography>
                )}
              </CardActions>
              <Box
                sx={{
                  m: 1
                }}>
                <Typography sx={textStyles.title}>{training?.title}</Typography>
                <Typography sx={textStyles.duration}>{training?.duration} minutes</Typography>
                <Typography sx={textStyles.description}>{training?.description}</Typography>
              </Box>
            </CardContent>
            <CardActions sx={cardListStyles.cardActions}>
              <Button onClick={() => handleClickOpen(training)} sx={cardListStyles.viewButton}>
                {training?.button}
              </Button>
              {training?.lastActive && (
                <Typography sx={cardListStyles.repeatTypography} color="text.secondary">
                  {t('training_repeat')} {training?.repeat}
                </Typography>
              )}
            </CardActions>
          </Box>
        ))}
      />

      <TrainingDialogs open={open} handleClose={handleClose} training={trainingSelected} />
    </Box>
  )
}
