import { AppBarProps } from '@mui/material'
import { useEffect } from 'react'

import { FullScreenProgress } from '../../../../common/components/FullScreenProgress'
import { RouteResponse } from '../../../../core/router'
import { LoggedOutModal, PrivateRoute, authSlice, useAuthUser } from '../../../../features/auth'
import { useTypedDispatch } from '../../../../store'
import { AuthenticatedRoute } from '../AuthenticatedRoute/AuthenticatedRoute'

type UserMiddlewareProps = AppBarProps & {
  route: RouteResponse | undefined
  handleChangeTheme: () => void
}

export const UserMiddleware = ({ route, handleChangeTheme }: UserMiddlewareProps): JSX.Element => {
  const dispatch = useTypedDispatch()
  const user = useAuthUser()

  useEffect(() => {
    if (user) {
      dispatch(authSlice.actions.setCurrentUser(user))
    }
  }, [user])

  if (!user) {
    return (
      <>
        <FullScreenProgress />
        <LoggedOutModal />
      </>
    )
  }

  return route && user && user.roles ? (
    <PrivateRoute route={route} handleChangeTheme={handleChangeTheme} tenantId={user.tenant_id} roles={user.roles} />
  ) : (
    <AuthenticatedRoute />
  )
}
