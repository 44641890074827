import { Route } from '@/core/router.types'
import type Participants from './Participants'

/*****
 *
 * Participants page routes..
 */

export default [
  {
    menu: { label: 'participants', icon: 'ParticipantsIcon' },
    path: '/participants',
    query: '',
    authorize: ['customer_success', 'customer_manager'],
    sidebarTab: true,
    component: () => import('./Participants'),
    response: (data: any) => ({
      title: 'Participants',
      description: 'List of participants in the system.',
      props: data
    })
  } as unknown as Route<typeof Participants>
] as const
