import React from 'react'
import { Button, ButtonProps } from '@mui/material'
import { SystemStyleObject, height } from '@mui/system'

import CustomPalette from '../../../theme/CustomPalette'

interface CustomButtonProps extends ButtonProps {
  children: React.ReactNode
  height?: string
  styles?: SystemStyleObject
  onClick?: (event: any) => void
}

const CustomButton: React.FC<CustomButtonProps> = ({ children, height, styles, onClick, ...props }) => (
  <Button
    data-testid="custom-button"
    onClick={onClick}
    type="submit"
    sx={{
      ...styles,
      pl: '24px',
      pr: '24px',
      height: height ? height : '40px',
      backgroundColor: props.disabled ? CustomPalette.SecondaryNightBlue50 : CustomPalette.PilotBlue,
      color: props.disabled ? CustomPalette.DisableGray : '#fff',
      border: '1px solid transparent',
      borderRadius: '20px',
      transition: '0.5s ease',
      '&:hover': {
        backgroundColor: CustomPalette.SecondaryCloud,
        color: CustomPalette.PilotBlue,
        borderColor: CustomPalette.PilotBlue,
        transition: ' 0.5s ease'
      },
      '&:disabled': { backgroundColor: CustomPalette.SecondaryNightBlue50, color: CustomPalette.SecondaryNightBlue400 },
      textTransform: 'none',
      fontSize: 16,
      fontWeight: '450'
    }}
    {...props}>
    {children}
  </Button>
)

export default CustomButton
