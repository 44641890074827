import { AppBarProps, List } from '@mui/material'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { UserRoles } from '../../../../../features/auth'
import { TenantSearchFormContext } from '../../../../../features/tenant'
import routes from '../../../../../routes'
import { useTypedSelector } from '../../../../../store'
import FontFaceLoader from '../../../../../theme/CustomFont'
import SidebarSection from '../SidebarSection'
import { TenantSearchItem } from '../TenantSearchItem/TenantSearchItem'
import { UserProfile } from '../UserProfile'
import NavBarMenuItem from './NavBarMenuItem'

type NavBarProps = AppBarProps & {
  isOpen?: boolean
  handleForceOpen: () => void
}

const userIsAuthorize = (authorize: string | any[], roles: any[]) => roles.some((role) => authorize?.includes(role))

export const NavBar = ({ isOpen, handleForceOpen }: NavBarProps) => {
  const user = useTypedSelector((state) => state.authSlice.user)

  const { t } = useTranslation('general')
  const [subdomain, setSubdomain] = useState<string>('')
  const rootUrl = new URL(document?.URL)
  const sidebarTabSum = routes.reduce((sum, route) => sum + (route.sidebarTab ? 1 : 0), 0) + 2

  useEffect(() => {
    const domain = new URL(rootUrl).hostname.replace('www.', '')
    const subdomain = domain.split('.')[0]
    setSubdomain(subdomain)
  }, [rootUrl])

  if (!user) {
    return <></>
  }

  return (
    <List>
      <FontFaceLoader />
      {routes.map(({ header, authorize, path, menu, headerStyles, showDivider, dividerStyles }: any, index: number) => {
        const isAuthorized = userIsAuthorize(authorize, user.roles)
        let marginTop

        if (user.roles.includes(UserRoles.Participant)) {
          marginTop = 4
        } else if (user.roles.includes(UserRoles.CustomerSuccess)) {
          marginTop = 13
        } else if (user.roles.includes(UserRoles.CustomerManager)) {
          marginTop = 10
        } else {
          marginTop = 72
        }

        const adjustedDividerStyles = { ...dividerStyles, mt: dividerStyles?.mt == 0 ? 2 : marginTop }

        return (
          isAuthorized &&
          menu && (
            <TenantSearchFormContext key={index}>
              <SidebarSection
                header={header && t(header)}
                showDivider={showDivider}
                dividerStyles={adjustedDividerStyles}
                headerStyles={isOpen ? { ...headerStyles, display: 'block' } : { ...headerStyles, display: 'none' }}>
                {index === sidebarTabSum && <UserProfile isOpen={isOpen} />}
                {menu.appendTenantSearch ? (
                  <>
                    <NavBarMenuItem isNavBarOpen={isOpen || false} path={path} menu={menu} />
                    <TenantSearchItem
                      isNavBarOpen={isOpen || false}
                      subdomain={subdomain}
                      handleForceOpen={handleForceOpen}
                    />
                  </>
                ) : (
                  <NavBarMenuItem isNavBarOpen={isOpen || false} path={path} menu={menu} />
                )}
              </SidebarSection>
            </TenantSearchFormContext>
          )
        )
      })}
    </List>
  )
}
