import CustomPalette from '@/theme/CustomPalette'

export const boxStyles = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  backgroundColor: CustomPalette.Air,
  height: '54px'
}

export const listItemButtonStyles = {
  display: 'block'
}

export const ListItemTextStyles = (isOpen: boolean) => ({
  '& span': {
    fontSize: '16px',
    fontFamily: 'BeausiteClassic-Regular',
    color: '#151830',
    fontWeight: '300',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    paddingRight: '.2rem'
  },
  opacity: isOpen ? 1 : 0,
  ml: '2.75rem'
})

export const menuStyles = {
  mt: '40px',
  ml: '-15px',
  '& .MuiPaper-root': {
    color: 'rgb(55, 65, 81)',
    width: '257px'
  }
}
