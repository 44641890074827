/* eslint-disable @typescript-eslint/ban-ts-comment */

import { BaseQueryFn, FetchArgs, FetchBaseQueryError, fetchBaseQuery } from '@reduxjs/toolkit/query'
import { Mutex } from 'async-mutex'
import Cookies from 'js-cookie'

import { RootState } from '@/store'
import { config as appConfig } from '../../core'
import { showLoggedOutModal, signOut } from '../../features/auth'
import { Detail } from './tenant/user'

const baseUrl = appConfig.api.origin
const SIGNATURE_EXPIRED_MSG = 'Authentication failed'

// Create a new mutex
const mutex = new Mutex()

const baseQuery = fetchBaseQuery({
  baseUrl,
  prepareHeaders: (headers, { getState }) => {
    headers.set('Accept', 'application/json')
    headers.set('Cache-Control', 'no-cache')
    headers.set('Pragma', 'no-cache')
    headers.set('Expires', '0')
    return headers
  },
  credentials: 'include'
})

const isAccessTokenValid = () => {
  const expiryTime = Cookies.get('access_token_expiry')
  if (!expiryTime) return false

  const currentTime = new Date().getTime()
  return currentTime < Number(expiryTime)
}

const handleSignOut = (api: { dispatch: (arg0: { payload: undefined; type: 'authSlice/signOut' }) => void }) => {
  if (!isAccessTokenValid()) {
    api.dispatch(signOut())
    window.location.href = `${appConfig.api.origin}/logout?redirect_url=${encodeURIComponent(
      appConfig.api.redirect_url
    )}`
  }
}

// @ts-ignore
const mutexCustomFetchBase: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
  args,
  api,
  extraOptions
) => {
  // wait until the mutex is available without locking it
  await mutex.waitForUnlock()

  const result = await baseQuery(args, api, extraOptions)

  if (result.error) {
    const errorDetail = result.error.data as Detail

    if (result.error.status == 401) {
      // Acquire the mutex here to prevent other API calls from executing
      // and triggering redirect to login.
      const release = await mutex.acquire()

      const state = api.getState() as RootState
      const user = state.authSlice?.user

      if (errorDetail.detail === SIGNATURE_EXPIRED_MSG && user) {
        api.dispatch(showLoggedOutModal())
      } else {
        // Redirecting to `/login` to avoid presenting the log out page to
        // a user who hasn't been previously logged in. This will happen when
        // the backend can't find the session cookie.
        const urlSearchParams = new URLSearchParams(window.location.search)
        const userEmail = urlSearchParams.get('username')
        window.location.href = `${appConfig.api.origin}/login?redirect_url=${encodeURIComponent(
          window.location.href
        )}&username=${encodeURIComponent(userEmail)}`
      }
    }
  }

  return result
}

export const authCustomFetchBase = mutexCustomFetchBase
