const CustomPalette = {
  PilotBlue: '#463ED7',
  PilotBlueShadow: '#463ED740',
  PrimaryPilotBlue: '#846CFF',
  PrimaryPilotBlue2: '#6B58F6',
  PrimaryPilotBlueLight900: '#002DA2',
  NightBlue200: '#151830',
  SecondaryNightBlue900: '#363752',
  SecondaryNightBlue600: '#6F708E',
  SecondaryNightBlue400: '#9999B8',
  SecondaryNightBlue300: '#AEAECE',
  SecondaryNightBlue200: '#C4C4E5',
  SecondaryNightBlue100: '#DADAFC',
  SecondaryNightBlue50: '#F1F1FF',
  Cloud: '#C4CFD4',
  SecondaryCloud: '#F8F8FA',
  Air: '#D7D9F7',
  NightBlueLight: '#262B57',
  NightBlue400: '#15172E',
  Chip: '#5664E5',
  DisableGray: '#c2c2c2',
  SecondaryGreen: '#E1FAE0',
  NightBlueLight800: '#212121',
  SuccessLightGreen: '#4D824F',
  white: '#FFFFFF',
  WarningOrange: '#C07402',
  SuccessGreen: '#4D824F',
  ErrorRed: '#BA4926',
  GrayDot: '#D9D9D9',
  NeutralColor: '#B827A1'
}

export default CustomPalette
