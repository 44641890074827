import { tenantApi as api } from './api'
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    subscriptionsGetCourseVariantsTenantsTenantIdCoursesCourseIdSubscriptionCourseVariantsGet: build.query<
      SubscriptionsGetCourseVariantsTenantsTenantIdCoursesCourseIdSubscriptionCourseVariantsGetApiResponse,
      SubscriptionsGetCourseVariantsTenantsTenantIdCoursesCourseIdSubscriptionCourseVariantsGetApiArg
    >({
      query: (queryArg) => ({
        url: `/tenants/${queryArg.tenantId}/courses/${queryArg.courseId}/subscription_course_variants`
      })
    }),
    subscriptionsGetSubscriptionCoursesTenantsTenantIdSubscriptionsSubscriptionIdSubscriptionCoursesGet: build.query<
      SubscriptionsGetSubscriptionCoursesTenantsTenantIdSubscriptionsSubscriptionIdSubscriptionCoursesGetApiResponse,
      SubscriptionsGetSubscriptionCoursesTenantsTenantIdSubscriptionsSubscriptionIdSubscriptionCoursesGetApiArg
    >({
      query: (queryArg) => ({
        url: `/tenants/${queryArg.tenantId}/subscriptions/${queryArg.subscriptionId}/subscription_courses`
      })
    }),
    getCoursesCoursesGet: build.query<GetCoursesCoursesGetApiResponse, GetCoursesCoursesGetApiArg>({
      query: (queryArg) => ({ url: `/courses/`, params: { query: queryArg.query } })
    }),
    createCourseCoursesPost: build.mutation<CreateCourseCoursesPostApiResponse, CreateCourseCoursesPostApiArg>({
      query: (queryArg) => ({ url: `/courses/`, method: 'POST', body: queryArg.createCourseDto })
    }),
    coursesGetCourseByCodeCoursesCourseCodeGet: build.query<
      CoursesGetCourseByCodeCoursesCourseCodeGetApiResponse,
      CoursesGetCourseByCodeCoursesCourseCodeGetApiArg
    >({
      query: (queryArg) => ({ url: `/courses/${queryArg.courseCode}` })
    }),
    coursesUpdateCourseByCodeCoursesCourseCodePut: build.mutation<
      CoursesUpdateCourseByCodeCoursesCourseCodePutApiResponse,
      CoursesUpdateCourseByCodeCoursesCourseCodePutApiArg
    >({
      query: (queryArg) => ({ url: `/courses/${queryArg.courseCode}`, method: 'PUT', body: queryArg.updateCourseDto })
    }),
    coursesDeleteCourseByCodeCoursesCourseCodeDelete: build.mutation<
      CoursesDeleteCourseByCodeCoursesCourseCodeDeleteApiResponse,
      CoursesDeleteCourseByCodeCoursesCourseCodeDeleteApiArg
    >({
      query: (queryArg) => ({ url: `/courses/${queryArg.courseCode}`, method: 'DELETE' })
    }),
    coursesGetCourseVariantByCodeCoursesCourseCodeCourseVariantsGet: build.query<
      CoursesGetCourseVariantByCodeCoursesCourseCodeCourseVariantsGetApiResponse,
      CoursesGetCourseVariantByCodeCoursesCourseCodeCourseVariantsGetApiArg
    >({
      query: (queryArg) => ({ url: `/courses/${queryArg.courseCode}/course_variants` })
    }),
    coursesCreateCourseVariantCoursesCourseCodeCourseVariantsPost: build.mutation<
      CoursesCreateCourseVariantCoursesCourseCodeCourseVariantsPostApiResponse,
      CoursesCreateCourseVariantCoursesCourseCodeCourseVariantsPostApiArg
    >({
      query: (queryArg) => ({
        url: `/courses/${queryArg.courseCode}/course_variants`,
        method: 'POST',
        body: queryArg.createCourseVariantDto
      })
    }),
    coursesGetCourseVariantByCodeAndVariantIdCoursesCourseCodeCourseVariantsCourseVariantIdGet: build.query<
      CoursesGetCourseVariantByCodeAndVariantIdCoursesCourseCodeCourseVariantsCourseVariantIdGetApiResponse,
      CoursesGetCourseVariantByCodeAndVariantIdCoursesCourseCodeCourseVariantsCourseVariantIdGetApiArg
    >({
      query: (queryArg) => ({ url: `/courses/${queryArg.courseCode}/course_variants/${queryArg.courseVariantId}` })
    }),
    coursesUpdateCourseVariantCoursesCourseCodeCourseVariantsCourseVariantIdPut: build.mutation<
      CoursesUpdateCourseVariantCoursesCourseCodeCourseVariantsCourseVariantIdPutApiResponse,
      CoursesUpdateCourseVariantCoursesCourseCodeCourseVariantsCourseVariantIdPutApiArg
    >({
      query: (queryArg) => ({
        url: `/courses/${queryArg.courseCode}/course_variants/${queryArg.courseVariantId}`,
        method: 'PUT',
        body: queryArg.updateCourseVariantDto
      })
    }),
    coursesDeleteCourseVariantCoursesCourseCodeCourseVariantsCourseVariantIdDelete: build.mutation<
      CoursesDeleteCourseVariantCoursesCourseCodeCourseVariantsCourseVariantIdDeleteApiResponse,
      CoursesDeleteCourseVariantCoursesCourseCodeCourseVariantsCourseVariantIdDeleteApiArg
    >({
      query: (queryArg) => ({
        url: `/courses/${queryArg.courseCode}/course_variants/${queryArg.courseVariantId}`,
        method: 'DELETE'
      })
    }),
    coursesSyncLmdhCourseVariantsCoursesCourseCodeCourseVariantsSyncLmdhPut: build.mutation<
      CoursesSyncLmdhCourseVariantsCoursesCourseCodeCourseVariantsSyncLmdhPutApiResponse,
      CoursesSyncLmdhCourseVariantsCoursesCourseCodeCourseVariantsSyncLmdhPutApiArg
    >({
      query: (queryArg) => ({ url: `/courses/${queryArg.courseCode}/course_variants/sync/lmdh`, method: 'PUT' })
    })
  }),
  overrideExisting: true
})
export { injectedRtkApi as enhancedApi }
export type SubscriptionsGetCourseVariantsTenantsTenantIdCoursesCourseIdSubscriptionCourseVariantsGetApiResponse =
  /** status 200 Successful Response */ CourseVariantDto[]
export type SubscriptionsGetCourseVariantsTenantsTenantIdCoursesCourseIdSubscriptionCourseVariantsGetApiArg = {
  tenantId: string
  courseId: string
}
export type SubscriptionsGetSubscriptionCoursesTenantsTenantIdSubscriptionsSubscriptionIdSubscriptionCoursesGetApiResponse =
  /** status 200 Successful Response */ SubscriptionCoursesDto
export type SubscriptionsGetSubscriptionCoursesTenantsTenantIdSubscriptionsSubscriptionIdSubscriptionCoursesGetApiArg =
  {
    subscriptionId: string
    tenantId: string
  }
export type GetCoursesCoursesGetApiResponse = /** status 200 Successful Response */ CourseDto[]
export type GetCoursesCoursesGetApiArg = {
  query?: string
}
export type CreateCourseCoursesPostApiResponse = /** status 201 Successful Response */ CourseDto
export type CreateCourseCoursesPostApiArg = {
  createCourseDto: CreateCourseDto
}
export type CoursesGetCourseByCodeCoursesCourseCodeGetApiResponse = /** status 200 Successful Response */ CourseDto
export type CoursesGetCourseByCodeCoursesCourseCodeGetApiArg = {
  courseCode: string
}
export type CoursesUpdateCourseByCodeCoursesCourseCodePutApiResponse = /** status 200 Successful Response */ CourseDto
export type CoursesUpdateCourseByCodeCoursesCourseCodePutApiArg = {
  courseCode: string
  updateCourseDto: UpdateCourseDto
}
export type CoursesDeleteCourseByCodeCoursesCourseCodeDeleteApiResponse = /** status 204 Successful Response */ void
export type CoursesDeleteCourseByCodeCoursesCourseCodeDeleteApiArg = {
  courseCode: string
}
export type CoursesGetCourseVariantByCodeCoursesCourseCodeCourseVariantsGetApiResponse =
  /** status 200 Successful Response */ CourseVariantDto[]
export type CoursesGetCourseVariantByCodeCoursesCourseCodeCourseVariantsGetApiArg = {
  courseCode: string
}
export type CoursesCreateCourseVariantCoursesCourseCodeCourseVariantsPostApiResponse =
  /** status 201 Successful Response */ CourseVariantDto
export type CoursesCreateCourseVariantCoursesCourseCodeCourseVariantsPostApiArg = {
  courseCode: string
  createCourseVariantDto: CreateCourseVariantDto
}
export type CoursesGetCourseVariantByCodeAndVariantIdCoursesCourseCodeCourseVariantsCourseVariantIdGetApiResponse =
  /** status 200 Successful Response */ CourseVariantDto
export type CoursesGetCourseVariantByCodeAndVariantIdCoursesCourseCodeCourseVariantsCourseVariantIdGetApiArg = {
  courseVariantId: string
  courseCode: string
}
export type CoursesUpdateCourseVariantCoursesCourseCodeCourseVariantsCourseVariantIdPutApiResponse =
  /** status 200 Successful Response */ CourseVariantDto
export type CoursesUpdateCourseVariantCoursesCourseCodeCourseVariantsCourseVariantIdPutApiArg = {
  courseVariantId: string
  courseCode: string
  updateCourseVariantDto: UpdateCourseVariantDto
}
export type CoursesDeleteCourseVariantCoursesCourseCodeCourseVariantsCourseVariantIdDeleteApiResponse =
  /** status 204 Successful Response */ void
export type CoursesDeleteCourseVariantCoursesCourseCodeCourseVariantsCourseVariantIdDeleteApiArg = {
  courseVariantId: string
  courseCode: string
}
export type CoursesSyncLmdhCourseVariantsCoursesCourseCodeCourseVariantsSyncLmdhPutApiResponse =
  /** status 200 Successful Response */ boolean
export type CoursesSyncLmdhCourseVariantsCoursesCourseCodeCourseVariantsSyncLmdhPutApiArg = {
  courseCode: string
}
export type FormOfAddress = 'formal' | 'informal'
export type CourseVariantDto = {
  id: string
  tenant_id?: string
  title: string
  description: string
  learning_objectives: string[]
  language_display_name: string
  language_tag: string
  easy_language: boolean
  low_vision: boolean
  form_of_address: FormOfAddress
  tenant_name?: string
  created_at?: string
  updated_at?: string
}
export type ValidationError = {
  loc: (string | number)[]
  msg: string
  type: string
}
export type HttpValidationError = {
  detail?: ValidationError[]
}
export type CourseVariantModel = {
  created_at?: string
  updated_at?: string
  archived_at?: string
  id: string
  tenant_id?: string
  title: string
  description: string
  learning_objectives: string[]
  language_tag: string
  language_display_name: string
  easy_language: boolean
  low_vision: boolean
  form_of_address: FormOfAddress
  course_id: string
  lmdh_version_guid?: string
  lmdh_accessibility?: boolean
  tenant_name?: string
}
export type CourseWithCourseVariantsDto = {
  id: string
  name: string
  max_licenses: number
  variants: CourseVariantModel[]
}
export type SubscriptionCoursesDto = {
  id: string
  code: string
  recurring_rule_type: string
  recurring_interval: number
  recurring_interval_in_months: number
  license_start_date: string
  courses: CourseWithCourseVariantsDto[]
}
export type CourseAccessibilityEnum = 'AA-WCAG 2.1'
export type CourseDto = {
  id: string
  code: string
  name: string
  duration_in_minutes: number
  odoo_product_id: number
  avendoo_course_id?: string
  no_of_variants: number
  created_at?: string
  updated_at?: string
  accessibility?: CourseAccessibilityEnum
  lmdh_elearning_content_guid?: string
}
export type CourseSource = 'AVENDOO' | 'LMDH'
export type CreateCourseDto = {
  code: string
  name: string
  duration_in_minutes: number
  odoo_product_id: number
  avendoo_course_id?: string
  accessibility?: CourseAccessibilityEnum
  source: CourseSource
  lmdh_elearning_content_guid?: string
}
export type UpdateCourseDto = {
  code: string
  name: string
  duration_in_minutes: number
  odoo_product_id: number
  avendoo_course_id?: string
  accessibility?: CourseAccessibilityEnum
  source: CourseSource
  lmdh_elearning_content_guid?: string
}
export type CreateCourseVariantDto = {
  tenant_id?: string
  title: string
  description: string
  learning_objectives: string[]
  language_tag: string
  easy_language: boolean
  low_vision: boolean
  form_of_address: FormOfAddress
}
export type UpdateCourseVariantDto = {
  tenant_id?: string
  title: string
  description: string
  learning_objectives: string[]
  language_tag: string
  easy_language: boolean
  low_vision: boolean
  form_of_address: FormOfAddress
}
export const {
  useSubscriptionsGetCourseVariantsTenantsTenantIdCoursesCourseIdSubscriptionCourseVariantsGetQuery,
  useSubscriptionsGetSubscriptionCoursesTenantsTenantIdSubscriptionsSubscriptionIdSubscriptionCoursesGetQuery,
  useGetCoursesCoursesGetQuery,
  useCreateCourseCoursesPostMutation,
  useCoursesGetCourseByCodeCoursesCourseCodeGetQuery,
  useCoursesUpdateCourseByCodeCoursesCourseCodePutMutation,
  useCoursesDeleteCourseByCodeCoursesCourseCodeDeleteMutation,
  useCoursesGetCourseVariantByCodeCoursesCourseCodeCourseVariantsGetQuery,
  useCoursesCreateCourseVariantCoursesCourseCodeCourseVariantsPostMutation,
  useCoursesGetCourseVariantByCodeAndVariantIdCoursesCourseCodeCourseVariantsCourseVariantIdGetQuery,
  useCoursesUpdateCourseVariantCoursesCourseCodeCourseVariantsCourseVariantIdPutMutation,
  useCoursesDeleteCourseVariantCoursesCourseCodeCourseVariantsCourseVariantIdDeleteMutation,
  useCoursesSyncLmdhCourseVariantsCoursesCourseCodeCourseVariantsSyncLmdhPutMutation
} = injectedRtkApi
