import CustomPalette from '@/theme/CustomPalette'

export const AppBarHeaderStyle = { position: 'relative', backgroundColor: '#F8F8FA', boxShadow: 0 }

export const IconButtonStyle = { maxWidth: 230, mt: 2, ml: 5, flex: 1, ':hover': { bgcolor: '#F8F8FA' } }

export const BoxStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100vh',
  backgroundColor: '#F8F8FA',
  pl: 19
}

export const WelcomeTypographyStyle = {
  color: '#151830',
  fontSize: { xs: '1.5rem', sm: '2rem', md: '2.5rem' },
  pt: -1,
  pl: 3,
  marginLeft: '6px',
  fontFamily: 'BeausiteFit-Medium'
}

export const DescriptionTypographyStyle = { maxWidth: 410, color: '#151830', pt: -2, pl: 4, whiteSpace: 'pre-line' }

export const TitleTypographyStyle = { color: CustomPalette.NightBlue200, mt: -1, pl: 4, fontWeight: 700 }

export const CourseSelectTypographyStyle = { color: '#151830', mt: 1, pl: 4, fontWeight: 400 }

export const ContinueButtonStyle = (color: string | undefined) => ({
  borderRadius: '20px 20px 20px 20px',
  borderColor: color ?? CustomPalette.NeutralColor,
  backgroundColor: color ?? CustomPalette.NeutralColor,
  color: '#FFFFFF',
  fontSize: 14,
  marginLeft: '33px',
  ':hover': {
    bgcolor: color ?? CustomPalette.NeutralColor,
    borderColor: color ?? CustomPalette.NeutralColor,
    color: 'white'
  }
})

export const AppBarFooterStyle = { boxShadow: 0, backgroundColor: '#F8F8FA', top: 'auto', bottom: 0 }

export const BackButtonStyle = {
  color: '#463ED7',
  fontWeight: 400,
  fontSize: '16px',
  lineHeight: '24px',
  ml: 8,
  ':hover': {
    backgroundColor: CustomPalette.SecondaryNightBlue50,
    borderRadius: '20px'
  }
}

export const ImprintButtonStyle = {
  color: '#463ED7',
  fontWeight: 400,
  fontSize: '16px',
  lineHeight: '24px',
  textDecoration: 'underline',
  ':hover': {
    backgroundColor: CustomPalette.SecondaryNightBlue50,
    borderRadius: '20px'
  }
}

export const PageLanguageTypographyStyle = {
  color: '#5B5C78',
  fontWeight: 400,
  fontSize: '16px',
  lineHeight: '24px',
  pt: 0.7,
  ml: 25
}
