import { debounce } from '../../../core'
import { TenantSearchArgs } from '@/features/tenant'
import { tenantApi } from '../../../services/api/tenant/api'
import { useTypedDispatch } from '../../../store'
import { useCallback, useEffect, useMemo } from 'react'
import { parseTemplate } from 'url-template'
import { useTenantSearchFormContext } from './useTenantSearchFormContext'

const searchQs = parseTemplate('{name}')

export const useSearchTenantsArgs = (): TenantSearchArgs => {
  const { values } = useTenantSearchFormContext()

  return useMemo<TenantSearchArgs>(() => {
    if (!values) return { query: '' }
    return {
      query: decodeURIComponent(
        searchQs.expand({
          name: values.name && `${values.name}`
        })
      ).trim()
      // sort: values.sort,
      // per_page: values.per_page,
      // page: values.page,
    }
  }, [values])
}

export const useSearchTenantsState = () => {
  const searchArgs = useSearchTenantsArgs()
  return tenantApi.endpoints.getTenantsTenantsGet.useQueryState(searchArgs)
}

export const useSearchTenants = () => {
  const dispatch = useTypedDispatch()
  const searchArgs = useSearchTenantsArgs()

  const tenantSearchFn = useCallback(
    (args: typeof searchArgs) => {
      dispatch(tenantApi.endpoints.getTenantsTenantsGet.initiate(args))
    },
    [dispatch]
  )
  const debouncedTenantSearchFn = useMemo(
    () =>
      debounce((args: typeof searchArgs) => {
        tenantSearchFn(args)
      }, 100),

    [tenantSearchFn]
  )

  useEffect(() => {
    tenantSearchFn(searchArgs)
  }, [])

  useEffect(() => {
    debouncedTenantSearchFn(searchArgs)
  }, [searchArgs, debouncedTenantSearchFn])

  return useSearchTenantsState()
}
