import { ITenantState } from '@/features/auth/types'
import { useSearchTenants } from '@/features/tenant'
import { useHistory } from '@/hooks'
import { ListItem, ListItemButton, ListItemText, Menu } from '@mui/material'
import Box from '@mui/material/Box'
import MenuItem from '@mui/material/MenuItem'
import { To } from 'history'
import React, { SetStateAction, useEffect, useMemo } from 'react'
import { listItemButtonTenantStyles } from '../NavBar/NavBarStyles'
import { NavItemTenantSearch } from './NavItemTenantSearch'
import { ListItemTextStyles, boxStyles, listItemButtonStyles, menuStyles } from './TenantSearchItemStyles'

interface TenantSearchItemProps {
  subdomain: string
  isNavBarOpen: boolean
  handleForceOpen: () => void
}

export const TenantSearchItem: React.FC<TenantSearchItemProps> = ({ isNavBarOpen, subdomain, handleForceOpen }) => {
  const history = useHistory()
  const rootUrl = new URL(document?.URL)

  const [isMenuOpen, setIsMenuOpen] = React.useState(false)
  const [options, setOptions] = React.useState<ITenantState[]>([])
  const [currentTenant, setCurrentTenant] = React.useState<ITenantState>()
  const { data: tenantsList = [], isFetching } = useSearchTenants()

  useEffect(() => {
    if (tenantsList && tenantsList.length) {
      setOptions(tenantsList as unknown as ITenantState[])
      setCurrentTenant(tenantsList.find((item) => item.subdomain === subdomain) as unknown as ITenantState)
    }
  }, [tenantsList])

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const handleMenu = (vent: React.MouseEvent<HTMLElement>) => {
    handleForceOpen()
    setAnchorEl(vent.currentTarget as SetStateAction<HTMLElement | null>)
    setIsMenuOpen(true)
  }

  const handleClose = () => {
    setAnchorEl(null)
    setIsMenuOpen(false)
  }

  const handleTenantSelected = (tenant: ITenantState) => {
    if (tenant) {
      rootUrl.pathname = '/tenants'
      const domain = rootUrl.hostname.replace('www.', '')
      const subdomain = domain.split('.')[0]
      const newUrl = rootUrl.href.replace(`${subdomain}.`, `${tenant.subdomain}.`) + `/${tenant.id}`
      history.push(newUrl as To, {})
    }
  }

  return useMemo(
    () => (
      <>
        <Box sx={boxStyles}>
          <ListItem disablePadding sx={listItemButtonStyles}>
            <ListItemButton onClick={handleMenu} sx={listItemButtonTenantStyles(isNavBarOpen)}>
              <ListItemText primary={currentTenant && currentTenant.name} sx={ListItemTextStyles(isNavBarOpen)} />
              <NavItemTenantSearch
                isOpen={isNavBarOpen}
                icon="SearchTenantIcon"
                isTenantSearch={true}
                isSidebarOpen={isNavBarOpen}
              />
            </ListItemButton>
          </ListItem>
          <Menu
            sx={menuStyles}
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
            open={isMenuOpen}
            onClose={handleClose}>
            {options.map((tenant) => (
              <MenuItem
                disabled={tenant.subdomain === subdomain}
                key={tenant.id}
                value={tenant.subdomain}
                onClick={() => handleTenantSelected(tenant)}>
                {tenant.name}
              </MenuItem>
            ))}
          </Menu>
        </Box>
      </>
    ),
    [options, anchorEl, isMenuOpen, currentTenant, isNavBarOpen]
  )
}
