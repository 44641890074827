import DoneIcon from '@mui/icons-material/Done'
import ExpandIcon from '../../../../../icons/ExpandIcon'
import { useLanguagesGetLanguagesLanguagesGetQuery } from '../../../../../services/api/tenant/languages'
import {
  UpdateTenantDto,
  useGetTenantTenantsTenantIdGetQuery,
  useUpdateTenantTenantsTenantIdPutMutation
} from '../../../../../services/api/tenant/tenants'
import FontFaceLoader from '../../../../../theme/CustomFont'
import CustomPalette from '../../../../../theme/CustomPalette'
import i18n from '../../../../../locales/i18n'

import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, Typography } from '@mui/material'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

type LanguageSelectorProps = {
  tenantId: string
  selectedLanguage: string
  setSelectedLanguage: (value: string) => void
}

type Language = {
  tag: string
  display_name: string
}

const languageMapping: { [key: string]: string } = {
  de: 'de-DE',
  en: 'en-GB',
  es: 'es-ES',
  it: 'it-IT',
  fr: 'fr-FR',
  cs: 'cs-CZ',
  el: 'el-GR',
  hr: 'hr-HR',
  hu: 'hu-HU',
  nl: 'nl-NL',
  pl: 'pl-PL',
  pt: 'pt-PT',
  ru: 'ru-RU',
  ro: 'ro-RO',
  sk: 'sk-SK',
  sl: 'sl-SI',
  tr: 'tr-TR',
  bg: 'bg-BG',
  uk: 'uk-UA',
  sr: 'sr-RS',
  sv: 'sv-SE',
  et: 'et-EE',
  lt: 'lt-LT',
  lv: 'lv-LV',
  no: 'no-NO',
  vi: 'vi-VN',
  ar: 'ar-SA',
  ja: 'ja-JP',
  ko: 'ko-KR',
  da: 'da-DK',
  th: 'th-TH',
  hi: 'hi-IN',
  fi: 'fi-FI'
}

export const LanguageSelector = ({ tenantId, selectedLanguage, setSelectedLanguage }: LanguageSelectorProps) => {
  const { t } = useTranslation('Languages')
  const { data: languages } = useLanguagesGetLanguagesLanguagesGetQuery({ displayInUi: true })
  const { data: tenant } = useGetTenantTenantsTenantIdGetQuery({ tenantId })
  const [updateTenant] = useUpdateTenantTenantsTenantIdPutMutation()

  useEffect(() => {
    const detectedLanguage = i18n.language.split('-')[0]
    const mappedLanguage = languageMapping[detectedLanguage]
    selectedLanguage = mappedLanguage
    setSelectedLanguage(mappedLanguage)
  }, [tenant, selectedLanguage, setSelectedLanguage, languages])

  const handleChange = async (event: SelectChangeEvent<string>) => {
    const newLanguage = event.target.value

    if (!newLanguage) {
      console.error('Invalid language selected.')
      return
    }

    const updatedTenant: UpdateTenantDto = {
      ...tenant!,
      default_language_tag: newLanguage
    }

    try {
      await i18n.changeLanguage(newLanguage)
      await updateTenant({ tenantId, updateTenantDto: updatedTenant })
      setSelectedLanguage(newLanguage)
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <>
      <FontFaceLoader />
      <FormControl sx={{ color: CustomPalette.NightBlue200, mt: -1.5, ml: 2, mr: 6 }}>
        <InputLabel id="language-select-label"></InputLabel>
        <Select
          labelId="language-select-label"
          variant="standard"
          id="language-select"
          value={selectedLanguage}
          onChange={handleChange}
          IconComponent={ExpandIcon}
          renderValue={() => (
            <Typography
              sx={{
                fontSize: '16px',
                fontFamily: 'BeausiteClassic-Regular',
                color: CustomPalette.NightBlue200,
                mr: 2.5
              }}>
              {selectedLanguage && languages && Array.isArray(languages)
                ? t(languages.find((lang) => lang.tag === selectedLanguage)?.display_name || '')
                : ''}
            </Typography>
          )}
          sx={{
            '& .MuiSelect-icon': {
              color: CustomPalette.NightBlue200,
              fontFamily: 'BeausiteClassic-Regular',
              fontSize: '19px'
            },
            '&:before': { display: 'none' },
            '&:after': { display: 'none' }
          }}>
          {languages &&
            languages.map((lang: Language) => (
              <MenuItem
                key={lang.tag}
                value={lang.tag}
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  fontSize: '14px',
                  fontFamily: 'BeausiteClassic-Regular',
                  backgroundColor: selectedLanguage === lang.tag ? CustomPalette.Air : CustomPalette.SecondaryCloud,
                  '& .MuiSelect-icon': {
                    color: CustomPalette.Air
                  },
                  '&:hover': {
                    backgroundColor: CustomPalette.Air
                  }
                }}>
                <span style={{ flexGrow: 1 }}>{t(lang.display_name)}</span>
                {selectedLanguage === lang.tag && <DoneIcon sx={{ marginLeft: '20px' }} />}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </>
  )
}
